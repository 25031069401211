import http from "../../../../baseURL/httpcommon";

const getWorkerList = (
  supervisorId,
  filterName,
  skillName,
  phoneNo,
  page,
  size,
  sortBy,
  sortDir
) => {
  return http.get(
    `/workforce/workers?supervisorId=${supervisorId}&filterName=${filterName}&phoneNo=${phoneNo}&skill=${skillName}&page=${page}&size=${size}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};

const getWorkerTasksList = (
  workforceId,
  userId,
  priority,
  pageNumber,
  pageSize
) => {
  return http.get(
    `/task/worker/list?workforceId=${workforceId}&userId=${userId}&eTaskStatus=${priority}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=id&sortDir=desc`
  );
};

const workerSkills = () => {
  return http.get("/admin/skills/active");
};

const WorkerServices = {
  getWorkerList,
  getWorkerTasksList,
  workerSkills,
};

export default WorkerServices;
