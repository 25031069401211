import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import ServerSideTable from "../../../common components/serverSideTable";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import WorkerServices from "./services";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ServerSideTable from "../../../common components/serverSideTable";

const WorkersScreen = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItems, setTotalItems] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("id"); // Added state for sortBy
  const [sortDir, setSortDir] = useState("desc"); // Added state for sortDir

  // Snackbar states
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [skillName, setSkillName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  const decodetoken = jwtDecode(token);
  const supervisorId = decodetoken.id;

  const columns = [
    {
      field: "id",
      headerName: t("Worker ID"),
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "skills",
      headerName: t("Worker Skill"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "phoneNo",
      headerName: t("Phone No"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "address",
      sortable: false,
      headerName: t("Address"),
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      flex: 1,
      sortable: false,
      headerName: t("Action"),
      headerAlign: "center",
      align: "center",
      renderCell: (param) => (
        <Link
          className="btn btn-outline-secondary btn-blue font-14 ms-2"
          to="/dashboard/supervisor/workertask"
          state={{ rowData: param.row }}
        >
          {t("View Details")}
        </Link>
      ),
    },
  ];

  function getCompanies(e) {
    debugger;
    setLoading(true);
    if (e !== undefined) {
      console.log("getUsers", e, page, pageSize);
      setPage(e.page == undefined ? 0 : e.page);
      setPageSize(e.pageSize == undefined ? 10 : e.pageSize);
      if (e.sortBy !== undefined) setSortBy(e.sortBy); // Update sortBy if provided
      if (e.sortDir !== undefined) setSortDir(e.sortDir); // Update sortDir if provided
    }
  }

  const getAllWorkers = async () => {
    debugger;
    try {
      setLoading(true);
      const response = await WorkerServices.getWorkerList(
        supervisorId,
        name,
        skillName,
        phoneNo,
        page,
        pageSize,
        sortBy,
        sortDir
      );
      const { currentPage, totalPages, content, totalElements } =
        response.data.data;
      setCount(totalPages);
      setTotalItems(totalElements);
      setPageNumber(currentPage);
      setData(content);
      console.log("get_all_workers_list", data);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 401) {
          setMessage(data.message);
          setLoading(false);
        } else if (status === 400) {
          setMessage(data.message);
          setLoading(false);
        } else {
          setMessage("An error occurred while logging in");
          setLoading(false);
        }
      } else {
        setMessage("Network Error");
        setLoading(false);
      }
      setSeverity("error");
      setOpen(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    getAllWorkers();
  }, [page, pageSize, sortBy, sortDir]);

  const [skills, setSkills] = useState([]);

  const getSkills = () => {
    WorkerServices.workerSkills()
      .then((response) => {
        setSkills(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getSkills();
  }, []);

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };
  const onChangePhoneNo = (e) => {
    const phoneNo = e.target.value;
    setPhoneNo(phoneNo);
  };

  const onChangeSkill = (e) => {
    const skillName = e.target.value;
    setSkillName(skillName);
  };

  const handleSearch = () => {
    getAllWorkers();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div class="card radius-10 p-4 px-3">
        <div class="card-header bg-transparent border-0">
          <div class="row g-3 align-items-center">
            <div class="col-4 col-md-2 col-lg-2">
              <h5 class="mb-3">{t("Workers")}</h5>
            </div>
            <div class="col-8 col-md-10 col-lg-10">
              {/* <div class="text-right">
                <button
                  class="btn btn-outline-secondary dropdown-toggle btn-blue px-3"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  All
                </button>
                <ul class="dropdown-menu dropdown-menu-end border-b">
                  <li>
                    <a class="dropdown-item" href="#">
                      All
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Absent
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Checked-in
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Checked-out
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Break
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Outside Work
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        <div
          className="row row-cols-1 row-cols-lg-2 g-3 align-items-center pb-3"
          style={{ marginLeft: 10 }}
        >
          <div className="col-12 col-lg-3 col-xl-3">
            <TextField
              fullWidth
              id="name"
              size="small"
              name="name"
              label={t("Search by Name")}
              variant="outlined"
              value={name}
              onChange={onChangeName}
            />
          </div>
          <div className="col-12 col-lg-3 col-xl-3">
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">
                {t("Skills")}
              </InputLabel>
              <Select
                labelId="Skills"
                id="skills"
                label={t("Skills")}
                value={skillName}
                onChange={onChangeSkill}
              >
                <MenuItem value="">All</MenuItem>
                {skills.map((item) => (
                  <MenuItem value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-12 col-lg-3 col-xl-3">
            <TextField
              fullWidth
              id="phoneNo"
              size="small"
              name="phoneNo"
              inputMode="numeric"
              pattern="[0-9]*"
              type="text"
              label={t("Search by Phone No")}
              variant="outlined"
              value={phoneNo}
              onChange={onChangePhoneNo}
            />
          </div>
          <div className="col-12 col-lg-3 col-xl-3">
            <Button variant="contained" color="primary" onClick={handleSearch}>
              {t("Search")}
            </Button>
          </div>
        </div>
        <div style={{ height: 650, width: "100%" }}>
          {count > 0 ? (
            <ServerSideTable
              pageSize={pageSize}
              page={page}
              sortBy={sortBy}
              sortDir={sortDir}
              count={count}
              functionName={getCompanies}
              data={data}
              loading={loading}
              columns={columns}
              idname="id"
            />
          ) : (
            // <p>No Task Found</p>
            <ServerSideTable
              pageSize={10}
              page={0}
              count={count}
              functionName={getCompanies}
              data={data}
              loading={loading}
              columns={columns}
              idname="id"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default WorkersScreen;
