import { jwtDecode } from "jwt-decode";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

const SideNav = () => {
  const token = localStorage.getItem("token");
  const jwt = token ? jwtDecode(token) : null;
  //const jwt = jwtDecode(localStorage.getItem("token"));
  const role = jwt?.userType;
  const menuSetting = useRef(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (!menuSetting.current) {
      var script = document.createElement("script");
      var menuCode = `$(document).ready(function(){
        $("#menu").metisMenu()
      });`;
      script.innerHTML = menuCode;
      document.body.appendChild(script);
      menuSetting.current = true;
    }
  }, []);

  const workerMenu = [
    {
      path: "/dashboard/supervisor/home",
      title: t("Home"),
      icon: "bi bi-house-door",
    },

    {
      path: "/dashboard/supervisor/tasks",
      title: t("Tasks"),
      icon: "fadeIn animated bx bx-file",
    },
    //report navigation
    {
      path: "",
      title: t("Reports"),
      icon: "fadeIn animated bx bx-spreadsheet",
      child: [
        {
          path: "/dashboard/supervisor/taskreport",
          title: t("Task Reports"),
          icon: "bi bi-journal-check",
        },
        {
          path: "/dashboard/supervisor/generalreport",
          title: t("General Reports"),
          icon: "bi bi-file-earmark-text",
        },
      ],
    },
    {
      path: "/dashboard/supervisor/workers",
      title: t("Workers"),
      icon: "fadeIn animated bx bx-user-plus",
    },
    {
      path: "/dashboard/supervisor/timesheet",
      title: t("Timesheet"),
      icon: "fadeIn animated bx bx-time-five",
    },
    {
      path: "/dashboard/supervisor/request",
      title: t("Requests"),
      icon: "fadeIn animated bx bx-bell",
    },
    {
      path: "/dashboard/supervisor/messages",
      title: t("Messages"),
      icon: "fadeIn animated bx bx-message-square-detail",
    },
    {
      path: "/dashboard/supervisor/settings",
      title: t("Settings"),
      icon: "lni lni-cog",
    },

    // {
    //   path: "/dashboard/supervisor/notification",
    //   title: t("Notification"),
    //   icon: "fadeIn animated bx bx-notification",
    // },

    {
      path: "/dashboard/supervisor/myProfile",
      title: t("My Profile"),
      icon: "fadeIn animated bx bx-user-circle",
    },
  ];

  return (
    <>
      {jwt !== null ? (
        <aside className="sidebar-wrapper" data-simplebar="true">
          <div className="sidebar-header">
            <Link to="/dashboard/supervisor/home">
              <img
                src="/assets/images/brand-logo-2.png"
                className="logo-icon"
                alt="logo icon"
              />
            </Link>
          </div>

          <ul className="metismenu" id="menu">
            {role === "SUPERVISOR"
              ? workerMenu.map((item, i) => (
                  <li key={i}>
                    {item.child && item.child.length > 0 ? (
                      <a href="javascript:;" className="has-arrow">
                        <div className="parent-icon">
                          <i className={item.icon}></i>
                        </div>
                        <div className="menu-title">{item.title}</div>
                      </a>
                    ) : (
                      <NavLink to={item.path}>
                        <div className="parent-icon">
                          <i className={item.icon}></i>
                        </div>
                        <div className="menu-title">{item.title}</div>
                      </NavLink>
                    )}

                    {item.child ? (
                      <ul>
                        {item.child.map((child) => (
                          <li key={child.path}>
                            <NavLink to={child.path}>
                              <div className="parent-icon">
                                <i
                                  className={child.icon}
                                  style={{
                                    fontFamily: "boxicons !important",
                                    marginLeft: "10px",
                                    height: "20px",
                                  }}
                                ></i>
                              </div>
                              <div className="menu-title">{child.title}</div>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                ))
              : null}
          </ul>
        </aside>
      ) : null}
    </>
  );
};

export default SideNav;
